
  
    
  import FETCHING from '../../library/fetching'
  import DATAMASTER from '../../library/dataMaster'
  import UMUM from '../../library/umum'
  
  export default {
    data() {
      return {
  
        form : {
          pembangunan_list_id : '',
          kegiatan_sub_kode : "",
          kegiatan_sub_rincian : "",
          kegiatan_sub_uraian : "",
          kegiatan_uraian : "",
          kegiatan_kode : "",
          unit_kerja : "",
          id : "",
          type_kegiatan : "",
          tahun_anggaran : "",
          kd_satker : "",
          kd_satker_str : "",
          nama_satker : "",
          kd_rup : "",
          kd_rup_lokal : "",
          kd_klpd : "",
          nama_klpd : "",
          jenis_klpd : "",
          nama_paket : "",
          nama_ppk : "",
          nip_ppk : "",
          username_ppk : "",
          pagu : 0,
          tgl_buat_paket : "",
          tgl_pengumuman_paket : "",
          uraian_pekerjaan : "",
          volume_pekerjaan : "",
          status_aktif_rup : 1,
          status_delete_rup : 0,
          status_umumkan_rup : "",
          kd_klpd_penyelenggara : "",
          nama_klpd_penyelenggara : "",
          nama_satker_penyelenggara : "",
          tgl_akhir_pelaksanaan_kontrak : "",
          tgl_awal_pelaksanaan_kontrak : "",
          tipe_swakelola : "",
          alasan_non_ukm : "",
          jenis_pengadaan : "",
          kd_jenis_pengadaan : "",
          kd_metode_pengadaan : "",
          kd_rup_swakelola : "",
          metode_pengadaan : "",
          spesifikasi_pekerjaan : "",
          status_konsolidasi : "",
          status_pdn : "",
          status_pradipa : "",
          status_ukm : "",
          tgl_akhir_kontrak : "",
          tgl_akhir_pemanfaatan : "",
          tgl_akhir_pemilihan : "",
          tgl_awal_kontrak : "",
          tgl_awal_pemanfaatan : "",
          tgl_awal_pemilihan : "",
          tipe_paket : "",

        },
        kegiatanSub : {
          kegiatan_sub_kode : [],
          unit_kerja : '',
          tahun : '',
          kegiatan_sub_id : '',
          sumber_dana : '',
          uraian : '',
          kode_full  : '',
          master_sumber_dana_uraian : '',

        },
        tag : {
          id : '',
          kegiatan_sub_kode : '',
          uraian : '',
          keterangan : '',
        },
        label : {
          id : '',
          kegiatan_sub_tag_id : '',
          uraian : '',
          rincian : [{
            uraian : '',
            koefisien : '',
            satuan : '',
            harga : 0,
            ppn : 0,
            jml : 0,
          }],
        },
        rincian : {
          id : '',
          kegiatan_sub_label_id : '',
          kegiatan_sub_kode : '',
          uraian : '',
          koefisien : '',
          satuan : '',
          harga : 0,
          ppn : 0,
          jml : 0,
        },

        target : {
          id : 0,
          rincian_id : 0,
          pembangunan_list_id : 0,
          jan : 0,
          feb : 0,
          mar : 0,
          apr : 0,
          mei : 0,
          jun : 0,
          jul : 0,
          agu : 0,
          sep : 0,
          okt : 0,
          nov : 0,
          des : 0,
          thn : 0,
          status : 0,
        },
        realisasi : {
          id : '',
          rincian_id  : '',
          pembangunan_list_id  : '',
          uraian : '',
          realisasi  : '',
          dd : 0,
          mm : 0,
          yy : 0,
          tgl : '',
          keterangan : '',
          kk_jenis : 1,
        },

        realisasi_target_persen : 100,
        realisasi_target_capaian : 0,

        lampiran : {
          id : '',
          file : null,
          uraian : '',
          type : '',
          keterangan : '',
          fileref : '',
          filetable : 'realisasi_fisik',
          file_old: "",
        },

        filterku : {
            program_kode : '',
            tahun : '',
            unit_kerja : '',
            instansi : '',
        },

        target_persen : 100,
  
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        autocomplete_db : '',
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        
  
  
      
        list_data : [],
        list_tag : [],
        list_realisasi : [],
        list_lampiran : [],
  
        page_first: 1,
        page_last: 0,
        page_limit : 10,
        cari_value: "",
        cek_load_data : true,

        mdl_view_realisasi : false,
        mdl_add_realisasi : false,
        mdl_edit_realisasi : false,
        mdl_hapus_realisasi : false,

        mdl_list_lampiran : false,
        mdl_add_lampiran : false,
        mdl_edit_lampiran : false,
        mdl_hapus_lampiran : false,
        mdl_view_lampiran : false,
  
  
        mdl_view_detile_target : false,
        mdl_sync_rincian_kegiatan : false,

        mdl_view_detile_paket : false,
  
  
        FETCHING : FETCHING,
        DATAMASTER : DATAMASTER,
        UMUM : UMUM,
      }
    },
    methods: {
  
  
      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.CT_TARGET_PEMBANGUNAN + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
                instansi : this.filterku.instansi,
                unit_kerja : this.filterku.unit_kerja,
                tahun : this.filterku.tahun,
                program_kode : this.filterku.program_kode,
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
                this.$store.commit("hideLoading");
                console.log(res_data);
        });
      },
  
  
      selectData : function(data){

        console.log(data)

          this.form.pembangunan_list_id = data.pembangunan_list_id ;
          this.form.kegiatan_sub_kode = data.kegiatan_sub_kode ;
          this.form.kegiatan_sub_rincian = data.kegiatan_sub_rincian ;
          this.form.kegiatan_sub_uraian = data.kegiatan_sub_uraian ;
          this.form.kegiatan_uraian = data.kegiatan_uraian ;
          this.form.kegiatan_kode = data.kegiatan_kode ;
          this.form.unit_kerja = data.unit_kerja ;
          this.form.id = data.id ;
          this.form.type_kegiatan = data.type_kegiatan ;
          this.form.tahun_anggaran = data.tahun_anggaran ;
          this.form.kd_satker = data.kd_satker ;
          this.form.kd_satker_str = data.kd_satker_str ;
          this.form.nama_satker = data.nama_satker ;
          this.form.kd_rup = data.kd_rup ;
          this.form.kd_rup_lokal = data.kd_rup_lokal ;
          this.form.kd_klpd = data.kd_klpd ;
          this.form.nama_klpd = data.nama_klpd ;
          this.form.jenis_klpd = data.jenis_klpd ;
          this.form.nama_paket = data.nama_paket ;
          this.form.nama_ppk = data.nama_ppk ;
          this.form.nip_ppk = data.nip_ppk ;
          this.form.username_ppk = data.username_ppk ;
          this.form.pagu = data.pagu ;
          this.form.tgl_buat_paket = data.tgl_buat_paket ;
          this.form.tgl_pengumuman_paket = data.tgl_pengumuman_paket ;
          this.form.uraian_pekerjaan = data.uraian_pekerjaan ;
          this.form.volume_pekerjaan = data.volume_pekerjaan ;
          this.form.status_aktif_rup = data.status_aktif_rup ;
          this.form.status_delete_rup = data.status_delete_rup ;
          this.form.status_umumkan_rup = data.status_umumkan_rup ;
          this.form.kd_klpd_penyelenggara = data.kd_klpd_penyelenggara ;
          this.form.nama_klpd_penyelenggara = data.nama_klpd_penyelenggara ;
          this.form.nama_satker_penyelenggara = data.nama_satker_penyelenggara ;
          this.form.tgl_akhir_pelaksanaan_kontrak = data.tgl_akhir_pelaksanaan_kontrak ;
          this.form.tgl_awal_pelaksanaan_kontrak = data.tgl_awal_pelaksanaan_kontrak ;
          this.form.tipe_swakelola = data.tipe_swakelola ;
          this.form.alasan_non_ukm = data.alasan_non_ukm ;
          this.form.jenis_pengadaan = data.jenis_pengadaan ;
          this.form.kd_jenis_pengadaan = data.kd_jenis_pengadaan ;
          this.form.kd_metode_pengadaan = data.kd_metode_pengadaan ;
          this.form.kd_rup_swakelola = data.kd_rup_swakelola ;
          this.form.metode_pengadaan = data.metode_pengadaan ;
          this.form.spesifikasi_pekerjaan = data.spesifikasi_pekerjaan ;
          this.form.status_konsolidasi = data.status_konsolidasi ;
          this.form.status_pdn = data.status_pdn ;
          this.form.status_pradipa = data.status_pradipa ;
          this.form.status_ukm = data.status_ukm ;
          this.form.tgl_akhir_kontrak = data.tgl_akhir_kontrak ;
          this.form.tgl_akhir_pemanfaatan = data.tgl_akhir_pemanfaatan ;
          this.form.tgl_akhir_pemilihan = data.tgl_akhir_pemilihan ;
          this.form.tgl_awal_kontrak = data.tgl_awal_kontrak ;
          this.form.tgl_awal_pemanfaatan = data.tgl_awal_pemanfaatan ;
          this.form.tgl_awal_pemilihan = data.tgl_awal_pemilihan ;
          this.form.tipe_paket = data.tipe_paket ;




          this.target.rincian_id = data.kegiatan_sub_rincian ;
          this.target.pembangunan_list_id = data.pembangunan_list_id ;
          this.realisasi.pembangunan_list_id = data.pembangunan_list_id ;
          this.realisasi.rincian_id = data.kegiatan_sub_rincian ;
          this.target.thn = data.tahun_anggaran;

      },

      getViewKegiatanSubTag : function(){
        // this.$store.commit("shoWLoading");
        this.list_tag = [];
        fetch(this.$store.state.url.CT_KEGIATAN_SUB_TAG + "list", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              kegiatan_sub_kode: this.form.kegiatan_sub_kode,
            })
        })
            .then(res => res.json())
            .then(res_data => {
                // this.$store.commit("hideLoading");
                this.list_tag = res_data
                // console.log(res_data);
        });
      },



      syncRincian : function(data){
        console.log(data)
        fetch(this.$store.state.url.CT_TARGET_PEMBANGUNAN + "syncRincian", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
              kegiatan_sub_rincian : data.id,
              id : this.form.pembangunan_list_id
            })
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.mdl_sync_rincian_kegiatan = false
            this.getView();
        });
      },

      viewTarget : function(){
        // this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.CT_TARGET_FISIK + "getOne", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.target)
        })
            .then(res => res.json())
            .then(res_data => {
                console.log(res_data);

                this.target.jan = res_data[0].jan
                this.target.feb = res_data[0].feb
                this.target.mar = res_data[0].mar
                this.target.apr = res_data[0].apr
                this.target.mei = res_data[0].mei
                this.target.jun = res_data[0].jun
                this.target.jul = res_data[0].jul
                this.target.agu = res_data[0].agu
                this.target.sep = res_data[0].sep
                this.target.okt = res_data[0].okt
                this.target.nov = res_data[0].nov
                this.target.des = res_data[0].des

          });
      },


      

      selectTarget : function(data){
        this.target.rincian_id = data.rincian_id ;
          this.target.jan = data.jan ;
          this.target.feb = data.feb ;
          this.target.mar = data.mar ;
          this.target.apr = data.apr ;
          this.target.mei = data.mei ;
          this.target.jun = data.jun ;
          this.target.jul = data.jul ;
          this.target.agu = data.agu ;
          this.target.sep = data.sep ;
          this.target.okt = data.okt ;
          this.target.nov = data.nov ;
          this.target.des = data.des ;
          this.target.thn = data.thn ;
          this.target.status = data.status ;
      },

      countMount : function(data){
        var datax = parseFloat(data.jan) + 
        parseFloat(data.feb) + 
        parseFloat(data.mar) + 
        parseFloat(data.apr) + 
        parseFloat(data.mei) + 
        parseFloat(data.jun) + 
        parseFloat(data.jul) + 
        parseFloat(data.agu) + 
        parseFloat(data.sep) + 
        parseFloat(data.okt) + 
        parseFloat(data.nov) + 
        parseFloat(data.des) 

        return datax

      },




      viewRealisasi : function(){

        this.realisasi_target_capaian = 0;

        fetch(this.$store.state.url.CT_REALISASI_FISIK + "viewV2", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                pembangunan_list_id: this.realisasi.pembangunan_list_id,
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_realisasi = res_data;
                // console.log(res_data);


                res_data.forEach(element => {
                  this.realisasi_target_capaian = this.realisasi_target_capaian + parseFloat(element.realisasi)
                });

        });
      },

      addRealisasi : function(){
        this.realisasi.dd = this.ReConvertFormatDate(this.realisasi.tgl).dd
        this.realisasi.mm = this.ReConvertFormatDate(this.realisasi.tgl).mm
        this.realisasi.yy = this.ReConvertFormatDate(this.realisasi.tgl).yy

        fetch(this.$store.state.url.CT_REALISASI_FISIK + "addDataV2", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.realisasi)
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.viewRealisasi();
        });


        // console.log(this.realisasi);
        // console.log(yy)
      },

      editRealisasi : function(){
        this.realisasi.dd = this.ReConvertFormatDate(this.realisasi.tgl).dd
        this.realisasi.mm = this.ReConvertFormatDate(this.realisasi.tgl).mm
        this.realisasi.yy = this.ReConvertFormatDate(this.realisasi.tgl).yy

        fetch(this.$store.state.url.CT_REALISASI_FISIK + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.realisasi)
        }).then(res_data => {
            this.Notify('Sukses Mengubah Data', 'primary', 'check_circle_outline');
            this.viewRealisasi();
            this.mdl_edit_realisasi = false;
        });

        // console.log(this.label.rincian);
      },

      removeRealisasi : function(E){
        fetch(this.$store.state.url.CT_REALISASI_FISIK + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.realisasi)
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.viewRealisasi();
        });

      },

      selectRealisasi : function(data){
          // console.log(data)
          this.realisasi.id = data.id ;
          this.realisasi.rincian_id  = data.rincian_id ;
          this.realisasi.uraian = data.uraian ;
          this.realisasi.realisasi  = data.realisasi ;
          // this.realisasi.tgl = data.tgl ;
          this.realisasi.tgl = data.yy +'-'+UMUM.addZeroDate(data.mm)+'-'+UMUM.addZeroDate(data.dd) ;


          // console.log(UMUM.addZeroDate(data.mm))

          this.realisasi.keterangan = data.keterangan ;


          this.lampiran.fileref = data.id;
          this.viewLampiran();


      },


      viewLampiran : function(){

        // console.log(this.lampiran)


        fetch(this.$store.state.url.CT_LAMPIRAN + "view", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.lampiran)
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_lampiran = res_data;
                console.log(res_data);

        });
      },

      addLampiran : function(){

          var formData = new FormData();
          formData.append('uraian', this.lampiran.uraian);
          formData.append('keterangan', this.lampiran.keterangan);
          formData.append('fileref', this.lampiran.fileref);
          formData.append('filetable', this.lampiran.filetable);
          formData.append('file', this.lampiran.file);

          fetch(this.$store.state.url.CT_LAMPIRAN + "addData", {
              method: "POST",
              headers: {
                  authorization : 'kikensbatara '+localStorage.token
              },
              body: formData
          }).then(res => res.json()).then((data_urusan) => {
              this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
              this.viewLampiran();
          });



      },

      editLampiran : function(){
        var formData = new FormData();
        formData.append('id', this.lampiran.id);
        formData.append('uraian', this.lampiran.uraian);
        formData.append('keterangan', this.lampiran.keterangan);
        formData.append('fileref', this.lampiran.fileref);
        formData.append('filetable', this.lampiran.filetable);
        formData.append('file', this.lampiran.file);
        formData.append('file_old', this.lampiran.file_old);

        fetch(this.$store.state.url.CT_LAMPIRAN + "editData", {
            method: "POST",
            headers: {
                authorization : 'kikensbatara '+localStorage.token
            },
            body: formData
        }).then(res => res.json()).then((data_urusan) => {
            this.Notify('Sukses Mengubah Data', 'primary', 'check_circle_outline');
            this.viewLampiran();
        });

      },

      removeLampiran : function(E){
        fetch(this.$store.state.url.CT_LAMPIRAN + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.lampiran)
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.viewLampiran();
        });

      },

      selectLampiran : function(data){

        console.log(data)

          this.lampiran.id = data.id ;
          // this.lampiran.file = data.file ;
          this.lampiran.file_old = data.file;
          this.lampiran.uraian = data.uraian ;
          this.lampiran.type = data.type ;
          this.lampiran.keterangan = data.keterangan ;
          this.lampiran.fileref = data.fileref ;
      },


  
  
      // ====================================== CONTOH eDOC ====================================
      
      ReConvertFormatDate : function(data){
        const d = new Date(data)
        return {
          yy : d.getFullYear(),
          mm : d.getMonth() + 1,
          dd : d.getDate(),
        }

      },
      
      onClickx(data){
        alert(data)
      },

      async onChangexInstansi(){
        await DATAMASTER.getInstansiAsync("");
        await this.onChangexUnitKerja();
      },

      async onChangexUnitKerja(){
        var unitkerjax = await DATAMASTER.getUnitKerjaAsync(this.filterku.instansi)
        console.log(unitkerjax[0]);
        this.filterku.unit_kerja = unitkerjax[0].id
        console.log(this.filterku.unit_kerja);
        this.getView();
      },
      // ====================================== CONTOH eDOC ====================================
  
    // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db_filter : function (val, update) {
          update(() => {
            if (val === '') {}
            else {FETCHING.getContohAtocomplete(val)}
          })
      },
  
      // ====================================== CONTOH AUTOCOMPLETE ====================================
  
  
  
  
  
  
  
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },
          indexing : function(index){
              var idx = ((this.page_first-1)*this.page_limit)+index
              return idx
          },
  
  
      // ====================================== PAGINATE ====================================
  
  
  
  
  
  
  
    },
  
    mounted () {
      FETCHING.getContohAtocomplete('')
      DATAMASTER.getTahun();



      let profilex = JSON.parse(localStorage.profile);
      let profile = profilex.profile;

      const d = new Date();
      let year = d.getFullYear();

      this.filterku.instansi = profile.instansi_id;
      this.filterku.unit_kerja = profile.unit_kerja;
      this.filterku.tahun = year;


      // this.getView();
      this.onChangexInstansi(); 

    },
  }
